<template>
  <div>
    <HeaderView/>
    <div v-if="error">{{ errorMessage }}</div>
    <div v-else-if="loading">Loading...</div>
    <div ref="contentContainer">
      <!-- Fallback content -->
      <p v-if="!html && !css">Content should appear here. If you see this message, the content failed to load.</p>
    </div>
    <FooterView />
  </div>
</template>

<script>
import HeaderView from "@/components/HeaderView.vue";
import FooterView from "@/components/FooterView.vue";
import axios from 'axios';
import DOMPurify from 'dompurify';

export default {
  components: {
    HeaderView,
    FooterView
  },
  data() {
    return {
      error: false,
      errorMessage: '',
      loading: true,
      debugMessage: 'Initializing...',
      html: '',
      css: '',
      styleElement: null
    };
  },
  async created() {
    const slug = this.$route.params.slug;
    const previewId = this.$route.params.previewId;

    try {
      this.loading = true;
      this.debugMessage = 'Fetching data...';
      let response;

      if (previewId) {
        response = await axios.get(`${process.env.VUE_APP_PAGE_PREVIEW_API_URL}/api/templates/preview/${previewId}`);
      } else {
        response = await axios.get(`${process.env.VUE_APP_PAGE_PREVIEW_API_URL}/api/templates/${slug}`);
      }

      if (response.data && response.data.data) {
        const { html, css } = response.data.data;
        this.html = html || '';
        this.css = css || '';
      } else {
        this.debugMessage = 'Invalid API response structure';
      }
    } catch (error) {
      this.error = true;
      this.errorMessage = error.response?.status === 404 ? 'Page not found' : 'An error occurred while loading the page';
      this.debugMessage = 'Error: ' + this.errorMessage;
    } finally {
      this.loading = false;
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.html && this.css) {
        this.applyHtmlAndCss(this.html, this.css);
      } else {
        this.debugMessage = 'HTML or CSS is missing';
      }
    });
  },
  beforeUnmount() {
    this.cleanupStyles();
  },
  methods: {
    applyHtmlAndCss(html, css) {
      this.debugMessage = 'Attempting to apply HTML and CSS...';
      this.$nextTick(() => {
        const contentContainer = this.$refs.contentContainer;
        if (contentContainer) {
          try {
            // Apply HTML
            const sanitizedHtml = this.sanitizeHtml(html);
            contentContainer.innerHTML = sanitizedHtml;
            
            // Apply CSS
            this.injectCss(css);
            
            // Process links
            this.processLinks();
            
            this.debugMessage = 'HTML and CSS applied successfully.';
          } catch (error) {
            this.debugMessage = 'Error applying HTML and CSS: ' + error.message;
          }
        } else {
          this.debugMessage = 'Content container not found!';
        }
      });
    },
    injectCss(css) {
      this.cleanupStyles(); // Remove any previously injected styles
      const importantCss = css.replace(/;/g, ' !important;');
      this.styleElement = document.createElement('style');
      this.styleElement.type = 'text/css';
      this.styleElement.appendChild(document.createTextNode(importantCss));
      document.head.appendChild(this.styleElement);
    },
    cleanupStyles() {
      if (this.styleElement && this.styleElement.parentNode) {
        this.styleElement.parentNode.removeChild(this.styleElement);
      }
    },
    sanitizeHtml(html) {
      return DOMPurify.sanitize(html, {
        ADD_TAGS: ['iframe'],
        ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling', 'target'],
      });
    },
    processLinks() {
      const links = this.$el.getElementsByTagName('a');
      Array.from(links).forEach(link => {
        link.addEventListener('click', this.handleLinkClick);
      });
    },
    handleLinkClick(event) {
      const link = event.currentTarget;
      const target = link.getAttribute('target');
      const href = link.getAttribute('href');

      if (target === '_blank') {
        event.preventDefault();
        window.open(href, '_blank', 'noopener,noreferrer');
      }

      // Add rel for security
      link.setAttribute('rel', 'noopener noreferrer');
    }
  },
  watch: {
    html(newHtml) {
      this.applyHtmlAndCss(newHtml, this.css);
    },
    css(newCss) {
      this.applyHtmlAndCss(this.html, newCss);
    }
  }
};
</script>
