<template>
  <div class="col-md-3 col-4" v-for="(card, index) in serviceCards" :key="index">
    <div class="demand-card">
      <div class="img-wrapper" :style="{ backgroundColor: card.color }">
        <img :src="card.imgSrc" alt="img">
        <ul class="categories">
          <li v-for="(feature, index) in card.features" :key="index">
            <a :href="feature.url">{{ feature.title }}</a>
          </li>
        </ul>
      </div>
      <div class="content-wrapper">
        <p class="p bold">{{ card.title }}</p>
        <ul class="feature">
          <li v-for="(feature, index) in card.features" :key="index">
            <a :href="feature.url">{{ feature.title }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ServiceCard',
  data() {
    return {
      serviceCards: [
        {
          title: 'Website Development',
          imgSrc: require('@/assets/images/demand-card-img-1.png'),
          features: [],
          color: '#6274ed'
        },
        {
          title: 'UI/UX Design',
          imgSrc: require('@/assets/images/demand-card-img-2.png'),
          features: [],
          color: '#1b2234'
        },
        {
          title: 'Mobile App Development',
          imgSrc: require('@/assets/images/demand-card-img-3.png'),
          features: [],
          color: '#ffc301'
        },
        {
          title: 'Ecommerce Development',
          imgSrc: require('@/assets/images/demand-card-img-4.png'),
          features: [],
          color: '#fad3e3'
        },
        {
          title: 'Custom Service Development',
          imgSrc: require('@/assets/images/demand-card-img-5.png'),
          features: [],
          color: '#3467ff'
        },
        {
          title: 'Digital Marketing',
          imgSrc: require('@/assets/images/demand-card-img-6.png'),
          features: [],
          color: '#060f17'
        },
        {
          title: 'Social Media Marketing',
          imgSrc: require('@/assets/images/demand-card-img-7.png'),
          features: [],
          color: '#6274ed'
        },
        {
          title: 'Corporate Branding',
          imgSrc: require('@/assets/images/demand-card-img-8.png'),
          features: [],
          color: '#ff4f28'
        }
      ],
      serviceCategories: [
        { id: 1, name: 'Social Media Marketing' },
        { id: 2, name: 'Website Development' },
        { id: 3, name: 'UI/UX Design' },
        { id: 4, name: 'Mobile App Development' },
        { id: 5, name: 'Shopify Development' },
        { id: 6, name: 'Ecommerce Development' },
        { id: 7, name: 'Custom Service Development' },
        { id: 8, name: 'Digital Marketing' },
        { id: 9, name: 'Corporate Branding' }
      ]
    };
  },
  mounted() {
    this.fetchServices();
  },
  methods: {
    async fetchServices() {
      try {
        const response = await axios.get(`${process.env.VUE_APP_API_URL}/api/service`);
        const services = response.data;

        this.serviceCards = this.serviceCards.map(card => {
          const matchedServices = services.filter(service => service.category === this.getCategoryId(card.title));
          if (matchedServices.length > 0) {
            card.features = matchedServices.map(service => ({
              title: service.service_title,
              url: `/service-detail/${service.id}`
            }));
          }
          return card;
        });
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    },
    getCategoryId(categoryName) {
      const category = this.serviceCategories.find(cat => cat.name === categoryName);
      return category ? category.id : null;
    }
  }
};
</script>
